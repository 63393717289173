import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import PostItem from '../components/PostItem';
import TitlePage from '../components/TitlePage';
import LocalizedLink from '../components/LocalizedLink';
import useTranslations from '../components/useTranslations';

import * as GS from '../layouts/styled';
import * as S from '../components/ListWrapper/styled';

import { Widget } from '@typeform/embed-react'

const Wizard = () => {
  // useTranslations is aware of the global context (and therefore also "locale")
  // so it'll automatically give back the right translations
  const {
    hello,
    subline,
    category,
    latestPosts,
    allPosts,
  } = useTranslations();

  return (
    <div className="homepage" style={{ width: "100%" }}>
      <SEO title="Home" />

      <div style={{ marginTop: "15px", padding: "20px" }}>
        <GS.Container style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
          <Widget id="Ukvk7eXs" style={{ width: '100%', height: "700px", padding: "20px" }} className="my-form" />
        </GS.Container>
      </div>

    </div>
  );
};

export default Wizard;
